import React from "react";
import {
  BannerPage,
  ContainerContentFixed,
  ContainerCta,
  ContentDisclaimer,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";

const Disclaimer = () => {
  return (
    <Layout>
      <SEO title={"Disclaimer"} description={"Disclaimer"} />
      <BannerPage title={"Disclaimer"} />
      <ContainerContentFixed>
        <ContentDisclaimer style={{ fontSize: "1rem" }} />
      </ContainerContentFixed>
      <ContainerCta
        title={"Any questions"}
        secondaryText={"Contact us"}
        secondaryLink={InternalLinkTypes.Contact}
      />
    </Layout>
  );
};

export default Disclaimer;
